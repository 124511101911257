<template>
  <div id="PMReport" class="scroll  p-3">
    <table
      class="w-full shadow-md">
      <tr class="HeaderTableColor fontheader" style=" height: 100px">
        <th class="text-center lg:w-1/4">
          {{ $t("PendingMedicalDocuments") }}
        </th>
        <th class=" text-center lg:w-1/4"  v-if="IsAppointment">
          {{ $t("AppointmentDate") }}
        </th>
        <th class="text-center lg:w-1/4"  v-else>
          {{ $t("SurgeryDate") }}
        </th>
        <th class="text-center lg:w-1/4"  v-if="IsAppointment">
          {{ $t("Doctor") }}
        </th>
        <th class="text-center lg:w-1/4"  v-else>
          {{ $t("Surgery") }}
        </th>
        <th
          class="text-center lg:w-1/4 text-center downloadCell"

        >

            </th>
      </tr>
      <tr :data="tr" :key="indextr" v-for="(tr, indextr) in Model" >
        <td>
          <p class="product-name font-medium truncate">
            {{ tr.DoctorRequestType.Name }}
          </p>
        </td>
        <td>
          <p v-if="tr.Date" class="product-name font-medium truncate" type="date">
            {{ tr.Date.split("T")[0] }}
          </p>
        </td>

        <td  v-if="tr.PatientReservationSessionID">
           <imageLazy
                      :imageStyle="{ 'border-radius': '20px' ,'width':'40px','height':'40px' }"
                      :imageClass="'rounded-full'"
                      :src="baseURL + tr.Doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
          <p
            class="product-name font-medium truncate"
            style="font: normal normal normal 15px/20px Futura PT"
          >
            {{$t("Dr") }} . {{ tr.Doctor.Name }}
          </p>
        </td>
        <td v-if="tr.PatientReservationSurgeryID">
          <p
            class="product-name font-medium truncate"
            style="font: normal normal normal 15px/20px Futura PT"
          >
            {{ tr.SurgeryName}}
          </p>
        </td> 

        <td class="downloadCell" style="background: primary; width: 20px" @click="updateRequest(tr)">
          <div class="m-3">
             <feather-icon
              icon="UploadIcon"
              style="background: primary"
              class="m-3"
              color="primary"
              @click="updateRequest(tr)"
            ></feather-icon>
            <!-- <vs-avatar
              @click="updateRequest(tr)"
              color="primary"
              icon-pack="feather"
              icon="icon-upload"
              style="background: primary"
              class="-m-3"
            /> -->
            <p
              style="
                color: #ffffff;
                font-size: 12px;
                opacity: 1;

                line-height: 30px;
              "
            >
              Upload
            </p>
          </div>
        </td>
      </tr>
  <tr v-if="Model.length==0">
         <td></td>
         <td><h3  class="w-full sm:w-auto text-center text-primary mt-5">{{$t("NoDataToshow")}}</h3></td>
         <td></td>
          </tr>
      <tr style="height: 50px"></tr>
    </table>


  </div>
</template>
<script>

import { domain, mainSetting } from "@/gloabelConstant.js";
import axios from "@/axios.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  data() {
    return {
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,

      uploaded:false,

      documentModel: {},
      ShowChooseFile: false,

    };
  },
   props: {
    Model: {
      type: Array,
      required: true,
    },

    IsAppointment:{
      type:Boolean,
     required: true,

    }
   },

  computed: {


  },
  components:{
    imageLazy
  },

  methods: {
    updateRequest(data) {
      debugger;
       this.documentModel= {};
      debugger;
      this.documentModel.DoctorID = data.DoctorID;
      this.documentModel.PatientReservationSessionID =data.PatientReservationSessionID;
      this.documentModel.PatientReservationSurgeryID =data.PatientReservationSurgeryID;
      this.documentModel.IsRequest = true;
      this.documentModel.PatientMedicalReportRequestID = data.ID;




      if(data.AnalysisTypeID||data.DoctorRequestTypeID==2)
      {
        if(data.AnalysisTypeID) this.documentModel.DocumentTypeID = data.AnalysisTypeID;
         this.documentModel.DocumentID =2;
      }

     if(data.ReportTypeID||data.DoctorRequestTypeID==3)
     {
    if(data.ReportTypeID)  this.documentModel.DocumentTypeID = data.ReportTypeID;
       this.documentModel.DocumentID = 3;
     }
     if(data.ScanTypeID||data.DoctorRequestTypeID==1)
     {
       if(data.ScanTypeID) this.documentModel.DocumentTypeID = data.ScanTypeID;
           this.documentModel.DocumentID = 1;
     }

     if(data.Description) this.documentModel.Description = data.Description;

      this.$emit("OpenUploadDocument", this.documentModel);
    },

  },
  created() {



  },
};
</script>
<style>
#PMReport .downloadCell {
  min-width: 50px;
  max-width: 60px;
  padding: 0px;
}
#PMReport table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;

}
#PMReport th,
#PMReport td {

   border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
  border-collapse: collapse;
  text-align: center;

}

#PMReport th {
  border-top: none;
}

#PMReport th:last-child {
  border-top-right-radius: 36px;
}
#PMReport th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}


#PMReport td:first-child {
  border-left: none;
}
#RSessPMReportion th,
tr {
  height: 5rem;
}

#PMReport .login-tabs-container {
  min-height: 505px;
}
.con-tab {
  padding-bottom: 14px;
}

.con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}
#PMReport .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
#PMReport .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
th,
tr {
  height: 5rem  !important;
}
.scroll{
overflow-x:auto !important;
}
</style>
