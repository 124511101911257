<template>
  <div>
    <div class="vx-row pb-10">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        class=""
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl font-bold fontSizeMedium">
        {{ $t("YourAllPendingDocuments") }}
      </p>
    </div>
    <div>
      <vx-card style="backgroun-color: none !important">
        <div slot="no-body" class="tabs-container px-8 pt-6">
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab
              :label="$t('AppointmentPendingDocuments') + '  (' + AppointmentCount + ')'"
              icon-pack="feather"
              icon="icon-alert-triangle"
            >
              <div class="tab-text">
                <pendingMedicalReport :IsAppointment="true" class="mt-4" @OpenUploadDocument="OpenUploadDocument" :Model="AppointMentDocumentList" />
              </div>
            </vs-tab>
            <vs-tab
              :label="$t('SurgeryPendingDocuments') + '  (' + SurgeryCount + ')'"
              icon-pack="feather"
              icon="icon-alert-octagon"
            >
              <div class="tab-text">
                <pendingMedicalReport :IsAppointment="false" class="mt-4" :Model="SurgeryDocumentList"  @OpenUploadDocument="OpenUploadDocument"/>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  <vs-popup fullscreen title="" :active.sync="ShowDocumentUpload">
      <UploadPendingMedicalReport
        @closePop="CloseDocumentPop"
        :documentModel="documentModel"
        @saveUploaded="removeModelFromList"
      ></UploadPendingMedicalReport>
    </vs-popup>
  </div>
  
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain, mainSetting } from "@/gloabelConstant.js";

import pendingMedicalReport from "./pendingMedicalReport.vue";
import UploadPendingMedicalReport from "@/views/patientService/UploadPendingMedicalReport.vue";

export default {
  data() {
    return {
      baseURL: domain,
      EnglishLan:false,
      activeTab: 0,
      AppointMentDocumentList: [],
      SurgeryDocumentList: [],
      uploaded:false,
      ShowDocumentUpload: false,
      documentModel: {
        Description:''
      },
      SurgeryCount:0,
      AppointmentCount:0,
    };
  },
   

  components: { pendingMedicalReport,UploadPendingMedicalReport},

  methods: {
removeModelFromList(model){
  debugger
  if(model.PatientReservationSessionID){
    var ItemIndex = this.AppointMentDocumentList.findIndex(p => p.ID == model.ID);
    this.AppointMentDocumentList.splice(ItemIndex, 1);
    this.AppointmentCount--;
  }
  else{
  var ItemIndex  = this.SurgeryDocumentList.findIndex(p => p.ID == model.ID);
    this.SurgeryDocumentList.splice(ItemIndex, 1);
    this.SurgeryCount--;
  }
  
this.ShowDocumentUpload = false;
},
 back() {
      this.$router.go(-1);
    },
      searchData(IsAppointment) {
      debugger;
      if (this.$store.state.AppActiveUser.Patient.ID) {
        var model = {};
        model.patientID = this.$store.state.AppActiveUser.Patient.ID;
        model.StausID = 1; //pending
        model.IsAppointment=IsAppointment;
        this.$store
          .dispatch("patientList/PatientMedicalReportRequest", model)
          .then((res) => {
            if (res.status == 200) {
              if(IsAppointment==true)
               {
                 this.AppointMentDocumentList = res.data.Data;
                   this.AppointMentDocumentList[0].IsAppointment=IsAppointment;
                    this.AppointmentCount=this.AppointMentDocumentList.length>0?this.AppointMentDocumentList.length:0;  
               }
              else
               {
                   this.SurgeryDocumentList = res.data.Data;
                    this.SurgeryDocumentList[0].IsAppointment=IsAppointment;
                    this.SurgeryCount=this.SurgeryDocumentList.length>0?this.SurgeryDocumentList.length:0;
                 }
              
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },


 OpenUploadDocument(data) {
      this.ShowDocumentUpload = true;
     debugger
      this.documentModel = data;
    },
 CloseDocumentPop() {
       this.ShowDocumentUpload = false;
        // this.searchData(false);
        // this.searchData(true);
       this.documentModel={};
    },
  },

   created() {
        this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
     this.searchData(false); 
     this.searchData(true); 
   
  },

};
</script>

